import React, { useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Stack, Button, Typography, Paper, SxProps, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { FastTextField } from '../InputField';
import { FastPatternFormatField } from '../PatternFormat';
import AiEnhancedTextField, { EnhanceType } from '../AiEnhancedTextField';
import { generateUniqueId } from 'utils/utils';
import { WorkExperience } from 'api/cvs/queries';
import { BeforeCapture, DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { getListStyle, handleBeforeCapture, handleDragEnd } from './DragAndDropUtils';

type EmploymentsFieldArrayProps = {
  id: string;
};

const minimizeSize = 196;

export default function EmploymentsFieldArray({ id }: EmploymentsFieldArrayProps) {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<{ workExperience: WorkExperience }>();

  const onDragEnd = (result: DropResult) => {
    const workExpArray = handleDragEnd(values.workExperience, result);

    if (workExpArray !== null) {
      setFieldValue('workExperience', workExpArray);
    }
  }

  const onBeforeCapture = (before: BeforeCapture) => {
    handleBeforeCapture(before, minimizeSize);
  }

  return (
    // Do not remove this div, it helps with the consistency of DND
    <div>
      <FieldArray
        name="workExperience"
        render={({
          form: {
            values: { workExperience },
          },
          push,
          remove,
        }) => (
          <DragDropContext
            onDragEnd={onDragEnd}
            onBeforeCapture={onBeforeCapture}
          >
            <Droppable droppableId="workExperience">
              {(droppableProvided, droppableSnapshot) => (
                <div
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                >
                  <Box sx={(theme) => ({ ...getListStyle(theme, droppableSnapshot.isDraggingOver) })}>
                    {workExperience && workExperience.map((employment: WorkExperience[0], index: number) => {
                      if (!employment.id) employment.id = generateUniqueId();

                      return (
                        <Draggable
                          key={employment.id}
                          draggableId={employment.id.toString()}
                          index={index}
                          isDragDisabled={workExperience.length <= 1}
                        >
                          {(draggableProvided, draggableSnapshot) => (
                            <Paper
                              elevation={draggableSnapshot.isDragging ? 20 : 2}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              sx={{
                                userSelect: 'none',
                                padding: 1,
                                mb: 1,
                                overflow: 'hidden'
                              }}
                            >
                              <Stack direction="column" spacing={1.5}>
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  {...draggableProvided.dragHandleProps}
                                  sx={{ height: 36 }}
                                >
                                  <Typography variant="h5">
                                    {t('general.assignment')} {index > 0 && index + 1}
                                  </Typography>
                                  {(workExperience.length > 1) && (
                                    <>
                                      <DragHandleIcon />
                                      <Button
                                        id={`${id}-${index}-remove`}
                                        startIcon={<RemoveIcon />}
                                        color="secondary"
                                        onClick={() => remove(index)}
                                      >
                                        {t('Common.Remove')}
                                      </Button>
                                    </>
                                  )}
                                </Stack>
                                <Stack
                                  className='collapse'
                                  direction="column"
                                  spacing={1.5}
                                >
                                  <FastTextField
                                    name={`workExperience.${index}.organization`}
                                    id={`${id}-${index}-assignment`}
                                    label={t('general.assignment')}
                                    fullWidth
                                  />
                                  <Stack direction={{ sm: 'column', md: 'row' }} spacing={{ sm: 2, md: 1.5 }}>
                                    <FastTextField
                                      name={`workExperience.${index}.jobTitle`}
                                      id={`${id}-${index}-title`}
                                      label={t('VerifyCv.PrevEmployments')}
                                      fullWidth
                                    />
                                    <FastPatternFormatField
                                      format="#### - ####"
                                      name={`workExperience.${index}.dates.years`}
                                      id={`${id}-${index}-date`}
                                      label={t('VerifyCv.DateOngoing')}
                                      placeholder="yyyy-yyyy"
                                      fullWidth
                                    />
                                  </Stack>
                                  <AiEnhancedTextField
                                    name={`workExperience.${index}.jobDescription`}
                                    id={`${id}-${index}-ai-description`}
                                    label={t('VerifyCv.EmploymentDescription')}
                                    enhanceType={EnhanceType.Resume}
                                  />
                                </Stack>
                              </Stack>
                            </Paper>
                          )}
                        </Draggable>
                      );
                    })}
                    {droppableProvided.placeholder}
                  </Box>
                  <Button
                    id={`${id}-add`}
                    startIcon={<AddIcon />}
                    color="secondary"
                    onClick={() => push(createNewWorkExperience())}
                    sx={{ mt: 1 }}
                  >
                    {t('VerifyCv.AddAnotherAssignment')}
                  </Button>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      />
    </div>
  );
}

export function createNewWorkExperience(): WorkExperience[0] {
  return {
    id: generateUniqueId(),
    jobTitle: '',
    organization: '',
    jobDescription: '',
    dates: {
      years: '',
    },
  };
}